import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"
import "./about.css"
import Layout from "../components/layout"
import SEO from "../components/seo"

class About extends React.Component {
  state = { slide: 1 }
  componentWillMount() {
    setInterval(() => this.change(), 3000)
  }

  change() {
    this.setState(({ slide }) => ({ slide: slide === 4 ? 1 : slide + 1 }))
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            phone: file(relativePath: { eq: "phone_back.png" }) {
              childImageSharp {
                fluid(maxHeight: 450) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            phone1: file(relativePath: { eq: "phone1.png" }) {
              childImageSharp {
                fluid(maxHeight: 450) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            phone2: file(relativePath: { eq: "phone2.png" }) {
              childImageSharp {
                fluid(maxHeight: 450) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            phone3: file(relativePath: { eq: "phone3.png" }) {
              childImageSharp {
                fluid(maxHeight: 450) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            phone4: file(relativePath: { eq: "phone4.png" }) {
              childImageSharp {
                fluid(maxHeight: 450) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            larissa: file(relativePath: { eq: "larissa.png" }) {
              childImageSharp {
                fluid(maxWidth: 260) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <Layout page="about">
            <SEO title="About" description="Neon Coat provides brands a direct connection to verified models." />
            <div className="about-box">
              <div className="about-content about-content-1 only-mobile">
                Neon Coat is a vetted network of professional models, providing
                brands with a trustworthy direct connection to the world’s elite
                influencers for gifting, events, photoshoots, and more.
              </div>
              <div className="flex-grid flex-desktop">
                <div className="about-phone-container">
                  <BackgroundImage
                    Tag="div"
                    className="about-phone vertical-center"
                    fluid={data.phone.childImageSharp.fluid}
                    backgroundColor={`#040e18`}
                  >
                    <Img
                      style={{ position: "absolute" }}
                      fluid={data.phone1.childImageSharp.fluid}
                      alt="Neon Coat Phone 1"
                      className={`slide ${
                        this.state.slide === 1 ? "active" : ""
                      }`}
                    />
                    <Img
                      style={{ position: "absolute" }}
                      fluid={data.phone2.childImageSharp.fluid}
                      alt="Neon Coat Phone 2"
                      className={`slide ${
                        this.state.slide === 2 ? "active" : ""
                      }`}
                    />
                    <Img
                      style={{ position: "absolute" }}
                      fluid={data.phone3.childImageSharp.fluid}
                      alt="Neon Coat Phone 3"
                      className={`slide ${
                        this.state.slide === 3 ? "active" : ""
                      }`}
                    />
                    <Img
                      style={{ position: "absolute" }}
                      fluid={data.phone4.childImageSharp.fluid}
                      alt="Neon Coat Phone 4"
                      className={`slide ${
                        this.state.slide === 4 ? "active" : ""
                      }`}
                    />
                  </BackgroundImage>
                </div>
                <div style={{ flex: "0 1 750px" }}>
                  <div className="about-content about-content-1 only-desktop-tablet">
                    Neon Coat is a vetted network of professional models,
                    providing brands with a trustworthy direct connection to the
                    world’s elite influencers for gifting, events, photoshoots,
                    and more.
                  </div>
                  <div className="about-content about-content-2">
                    <p>
                      Model-founded, Neon Coat empowers its members to curate
                      their own social lives by experiencing new brands through
                      a unique technology platform.{" "}
                    </p>
                    <p>
                      By sharing their experiences, our members increase brand
                      awareness around the world while boosting their own social
                      presence and brand. Members enjoy healthy alternatives to
                      the "promoter scene", with options for fitness classes,
                      volunteer experiences, gifts, interest groups, luxury
                      beauty treatments, high-end restaurants, and so much more.
                    </p>

                    <br />
                    <br className="only-desktop" />
                    <br className="only-desktop" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grid larissa">
              <div style={{ flex: "0 1 950px", marginTop: "40px" }}>
                <div className="flex-grid about-flex">
                  <div
                    style={{
                      flex: "2",

                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                    }}
                  >
                    <div className="about-title">
                      Larissa Drekonja{" "}
                      <span style={{ color: "#a7dcdf" }}>|</span>{" "}
                      <span style={{ color: "#999999" }}>Founder, Model</span>
                    </div>
                    <div className="about-message only-desktop-tablet">
                      <p>
                        Being a model isn’t always easy — I arrived in New York
                        with visions of stardom, but the reality I faced was far
                        less glamorous: loneliness, unsavory promoters, late
                        payments, cramped model apartments, and unsafe working
                        conditions. I realized these problems were shared by
                        many other models as well.
                      </p>
                      <p>
                        {" "}
                        I also saw that businesses and brands wanted to directly
                        connect with models to elevate their brand image and
                        increase their exposure, but there was no easy way to do
                        so.
                      </p>
                      <p>
                        {" "}
                        It made me think: could I unify the modeling community
                        and directly connect them with brands? As the wheels
                        were turning...Neon Coat was born!
                      </p>
                    </div>
                  </div>
                  <div className="larissa-image">
                    <div style={{ height: "52px" }} className="only-tablet" />
                    <Img
                      fluid={data.larissa.childImageSharp.fluid}
                      alt="Neon Coat Founder Larissa Drekonja"
                      className="about-image-container"
                    />
                  </div>
                  <div className="only-mobile">
                    <div className="about-message">
                      <p>
                        Being a model isn’t always easy — I arrived in New York
                        with visions of stardom, but the reality I faced was far
                        less glamorous: loneliness, unsavory promoters, late
                        payments, cramped model apartments, and unsafe working
                        conditions. I realized these problems were shared by
                        many other models as well.
                      </p>
                      <p>
                        {" "}
                        I also saw that businesses and brands wanted to directly
                        connect with models to elevate their brand image and
                        increase their exposure, but there was no easy way to do
                        so.
                      </p>
                      <p>
                        {" "}
                        It made me think: could I unify the modeling community
                        and directly connect them with brands? As the wheels
                        were turning...Neon Coat was born!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ maxWidth: "900px", margin: "auto" }}>
              <div class="embed-container-outer">
                <div class="embed-container">
                  <iframe
                    src="https://www.youtube.com/embed/_n3B3S39Qj0"
                    frameborder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>

              <br />
              <br className="only-desktop" />
            </div>
          </Layout>
        )}
      ></StaticQuery>
    )
  }
}

export default About
